import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "../services/base.service";
import { ToastrService } from "ngx-toastr";

const swal = require("sweetalert");

@Injectable()
export class NotificationsService extends BaseService {
  constructor(protected http: HttpClient, private toaster: ToastrService) {
    super(http);
  }

  success(title: string, message: string): any {
    this.toaster.success(message, title);
  }

  error(title: string, message: string): any {
    this.toaster.error(message, title);
  }

  info(title: string, message: string): any {
    this.toaster.info(message, title);
  }

  warning(title: string, message: string): any {
    this.toaster.warning(message, title);
  }

  confirm(
    title: string,
    text: string,
    icon?: string,
    confirmCallback?,
    cancelCallback?
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      swal({
        title,
        text,
        icon: icon == 'na'? "":"warning",
        buttons: {
          cancel: {
            text: "Cancel",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "bg-danger",
            closeModal: true,
          },
        },
      }).then((isConfirm) => {
        if (isConfirm) {
          if (confirmCallback) {
            confirmCallback();
          }
          resolve("");
        } else {
          if (cancelCallback) {
            cancelCallback();
          }
          reject();
        }
      });
    });
  }
}
